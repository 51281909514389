import React from 'react'
import styled from 'styled-components'

import { Row, Column, Text, Layout } from 'components'

import Error404 from 'images/Error404.png'

const NotFoundPage = () => {
  return (
    <Layout>
      <Row
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        p='0px 16px'
        height='100vh'
        backgroundColor='complement.50'
      >
        <Column>
          <Image src={Error404} alt='Error404' />
        </Column>
        <Column mt={25}>
          <Text textAlign='center' fontSize='24px' lineHeight='40px' color='primary.500' fontWeight={600}>
            Página não encontrada!
          </Text>
          <Text mt='8px' textAlign='center' fontSize='16px' lineHeight='24px' color='gray.800'>
            Desculpa, mas esta página encontra-se indisponível neste momento.
          </Text>
        </Column>
      </Row>
    </Layout>
  )
}

const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 460px;
  margin: auto;
`

export default NotFoundPage
